export default class DispatchTypeDomain {


  /**
   * 1:违规投放
   */
  public static DISPATCH_TYPE_VIOLATION = 1;

  /**
   * 2:乱停放
   */
  public static DISPATCH_TYPE_DISORDERLY = 2;

  /**
   * 3:运维违规
   */
  public static DISPATCH_TYPE_OPERATION = 3;

  /**
   * 4:破损/污渍
   */
  public static DISPATCH_TYPE_DAMAGED = 4;
  /**
   * 5:停放超限
   */
  public static DISPATCH_TYPE_PARKING_LIMIT = 5;
  /**
   * 6:市民投诉
   */
  public static DISPATCH_TYPE_PUBLIC_COMPLAINTS = 6;
  /**
   * 7:严重舆论
   */
  public static DISPATCH_TYPE_SERIOUS_OPINION = 7;
  /**
   * 8:私设停放点
   */
  public static DISPATCH_TYPE_PARKING_VIOLATION = 8;
  /**
   * 未处理 1
   */
  public static SUPERVISE_WAIT_DEAL = 1;
  /**
   * 处理中 2
   */
  public static SUPERVISE_DEALING = 2;
  /**
   * 已处理 3
   */
  public static SUPERVISE_HAS_DEAL = 3;

  /**
   * 调度车
   */
  public static DISPATCH_TYPE_CAR = 1;
  /**
   * 调度人
   */
  public static DISPATCH_TYPE_PERSON = 2;
}
