/**
 * local storage操作类，不要在外部操作
 */
import StorageDomain from "@/storage/storage-domain";
import UserInfo from "@/biz/user-info";
import WxUserinfo from "@/biz/wx-userinfo";
import AccessAuth from "@/biz/access-auth";

export class LocalStorageAgent {

  public static setWXAccessAuth(accessAuth: AccessAuth | null): void {
    localStorage.setItem(StorageDomain.WX_ACCESSAUTH, accessAuth ? JSON.stringify(accessAuth) : '');
  }

  public static getWXAccessAuth(): AccessAuth | null {
    const wxAccessAuthStr = localStorage.getItem(StorageDomain.WX_ACCESSAUTH);
    let accessAuth: AccessAuth = wxAccessAuthStr ? JSON.parse(wxAccessAuthStr) : null;
    return accessAuth;
  }

  public static setWXUserInfo(wxUserinfo: WxUserinfo | null): void {
    localStorage.setItem(StorageDomain.WX_USERINFO, wxUserinfo ? JSON.stringify(wxUserinfo) : '');
  }

  public static getWXUserInfo(): WxUserinfo | null {
    const wxUserinfoStr = localStorage.getItem(StorageDomain.WX_USERINFO);
    let wxUserinfo: WxUserinfo = wxUserinfoStr ? JSON.parse(wxUserinfoStr) : null;
    return wxUserinfo;
  }

  public static setUserInfo(user: UserInfo | null): void {
    localStorage.setItem(StorageDomain.USERINFO, user ? JSON.stringify(user) : '');
  }

  public static getUserInfo(): UserInfo | null {
    const userInfoJson = localStorage.getItem(StorageDomain.USERINFO);
    let userInfo: UserInfo = userInfoJson ? JSON.parse(userInfoJson) : null;
    return userInfo;
  }

  public static setLastedAccount(account: string) {
    localStorage.setItem(StorageDomain.LASTED_ACCOUNT, account);
  }

  public static getLastedAccount() {
    return localStorage.getItem(StorageDomain.LASTED_ACCOUNT);
  }


  public static setPhotoPath(bicyclePhotos: Array<string> | null): void {
    localStorage.setItem(StorageDomain.PHOTOPATH, bicyclePhotos ? JSON.stringify(bicyclePhotos) : '');
  }

  public static getPhotoPath(): Array<string> {
    const bicyclePhotosJson = localStorage.getItem(StorageDomain.PHOTOPATH);
    return bicyclePhotosJson ? JSON.parse(bicyclePhotosJson) : null;
  }


  public static setSubEventTypes(subEventTypes: Array<string> | null): void {
    localStorage.setItem(StorageDomain.SUB_EVENT_TYPES, subEventTypes ? JSON.stringify(subEventTypes) : '');
  }

  public static getSubEventTypes(): Array<string> {
    const subEventTypeJson = localStorage.getItem(StorageDomain.SUB_EVENT_TYPES);
    return subEventTypeJson ? JSON.parse(subEventTypeJson) : null;
  }
}
