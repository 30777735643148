export default class MapZoomDomain {
  /**
   * 10m
   */
  public static readonly MAP_ZOOM_19 = 19;
  /**
   * 25m
   */
  public static readonly MAP_ZOOM_18 = 18;
  /**
   * 50m
   */
  public static readonly MAP_ZOOM_17 = 17;
  /**
   * 100m
   */
  public static readonly MAP_ZOOM_16 = 16;
  /**
   * 200m
   */
  public static readonly MAP_ZOOM_15 = 15;
  /**
   * 500m
   */
  public static readonly MAP_ZOOM_14 = 14;
  /**
   * 1km
   */
  public static readonly MAP_ZOOM_13 = 13;
  /**
   * 2km
   */
  public static readonly MAP_ZOOM_12 = 12;
  /**
   * 5km
   */
  public static readonly MAP_ZOOM_11 = 11;
  /**
   * 10km
   */
  public static readonly MAP_ZOOM_10 = 10;
  /**
   * 20km
   */
  public static readonly MAP_ZOOM_9 = 9;
  /**
   * 30km
   */
  public static readonly MAP_ZOOM_8 = 8;
  /**
   * 50km
   */
  public static readonly MAP_ZOOM_7 = 7;
  /**
   * 100km
   */
  public static readonly MAP_ZOOM_6 = 6;
}
