import { Toast } from "vant";
import ValueUtil from "./value-util";

export class ToastUtil {

  /**
   * 显示loading,需要调用 hide 方法手动关闭
   * @param message
   */
  public static showLoading(message: string): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      message: message,
      forbidClick: true,
    });
  }

  /**
   * 显示消息内容
   * @param message
   */
  public static showMessage(message: string): void {
    if (ValueUtil.isStrEmpty(message)) {
      return;
    }
    Toast({
      duration: 1500,
      message: message,
      forbidClick: true,
      position: 'bottom',
    });
  }

  public static hide(): void {
    Toast.clear();
  }
}
