import {AxiosPromise, AxiosResponse} from "axios";
import axiosagent from "@/http/axios_agent";
import HttpRequestUtil from "@/http/HttpRequestUtil";
import ImageUtil from "@/util/image-util";

export class BicycleRequest {

  public static getBicycleList(center_lat: string, center_lon: string): AxiosPromise {
    let paramMap = new Map<string, string>();
    paramMap.set('center_lat', center_lat);
    paramMap.set("center_lon", center_lon);
    return axiosagent.shared_bicycle_service.get('app/rectangle/bicycles?' + HttpRequestUtil.encodeParameters(paramMap))
  }

  /**
   * 获取签名
   * @param url
   */
  public static getSign(url: string): AxiosPromise {
    return axiosagent.shared_bicycle_service.get('oauth/wx/config?web_url=' + url);
  }

  /**
   * 不登陆，微信授权 获取签名
   * @param url
   */
  public static getWxSign(url: string): AxiosPromise {
    return axiosagent.shared_bicycle_suggestion.get('oauth/wx/wx_config?web_url=' + url);
  }

  /**
   * 上传文件
   * @param file
   * @param path
   */
  public static uploadFile(file: File, path: string): AxiosPromise {
    return new Promise((resolve, reject) => {
      ImageUtil.compressImageFile(file, (resultData: string) => {
        resolve(`resolve:${resultData}`)
      })
    }).then((resultData: any) => {
      let timeStamp: number = new Date().getTime();
      const formData = new FormData();
      formData.append('img', ImageUtil.dataURLtoFile(resultData, `${path}_${timeStamp}.png`));
      formData.append('path', path);
      return axiosagent.shared_bicycle_service.post('imgs/upload', formData);
    })
  }


  /**
   * 微信授权上传文件
   * @param file
   * @param path
   */
  public static uploadWxFile(file: File, path: string): AxiosPromise {
    return new Promise((resolve, reject) => {
      ImageUtil.compressImageFile(file, (resultData: string) => {
        resolve(`resolve:${resultData}`)
      })
    }).then((resultData: any) => {
      let timeStamp: number = new Date().getTime();
      const formData = new FormData();
      formData.append('img', ImageUtil.dataURLtoFile(resultData, `${path}_${timeStamp}.png`));
      formData.append('path', path);
      return axiosagent.shared_bicycle_suggestion.post('wx/imgs/upload', formData);
    })
  }

  /**
   * 获取矩形区域内停放点
   * center_lon  string  T  中心点经度
   * center_lat  string  T  中心点纬度
   * long  int  F  比例尺长 默认105
   * wide  int  F  比例尺宽 默认83
   */
  public static getParkingPoints(center_lon: string, center_lat: string, long: number | null, wide: number | null, is_select_event_attribute: boolean, parking_point_attribute: number | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    paramMap.set('center_lat', center_lat);
    paramMap.set("center_lon", center_lon);
    if (long != null) {
      paramMap.set("long", String(long));
    }
    if (wide != null) {
      paramMap.set("wide", String(wide));
    }
    paramMap.set("is_select_event_attribute", String(is_select_event_attribute));
    if (is_select_event_attribute) {
      paramMap.set("parking_point_attribute", String(parking_point_attribute));
    }
    return axiosagent.shared_bicycle_service.get('app/rectangle/parking_points?' + HttpRequestUtil.encodeParameters(paramMap))
  }

  /**
   * 微信授权获取矩形区域内停放点
   * center_lon  string  T  中心点经度
   * center_lat  string  T  中心点纬度
   * long  int  F  比例尺长 默认105
   * wide  int  F  比例尺宽 默认83
   */
  public static getWxParkingPoints(center_lon: string, center_lat: string, long: number | null, wide: number | null, is_select_event_attribute: boolean, parking_point_attribute: number | null): AxiosPromise {
    let paramMap = new Map<string, string>();
    paramMap.set('center_lat', center_lat);
    paramMap.set("center_lon", center_lon);
    if (long != null) {
      paramMap.set("long", String(long));
    }
    if (wide != null) {
      paramMap.set("wide", String(wide));
    }
    paramMap.set("is_select_event_attribute", String(is_select_event_attribute));
    if (is_select_event_attribute) {
      paramMap.set("parking_point_attribute", String(parking_point_attribute));
    }
    return axiosagent.shared_bicycle_suggestion.get('wx/rectangle/parking_points?' + HttpRequestUtil.encodeParameters(paramMap))
  }

  /**
   * 扫码验车
   * @param {number} lon
   * @param {number} lat
   * @param {string} position
   * @param bicycleQrCode
   * @param bodyNum
   * @param {number} operationType 操作类型 1扫码验车 2单车监管
   * @param companyType
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static checkBicycleRecordInfo(lon: number, lat: number, position: string,
                                       bicycleQrCode: any, bodyNum: any, companyType: number) {
    return axiosagent.shared_bicycle_service.post('api/scan/bicycle_regulatory_infos', {
      position: position,
      lon: lon,
      lat: lat,
      bicycle_qrcode: bicycleQrCode,
      body_num: bodyNum,
      company_type: companyType
    });
  }

  /**
   * 获取矩形区域内企业人车数据
   * center_lon  string  T  中心点经度
   * center_lat  string  T  中心点纬度
   */
  public static getCompanyDispatchInfos(center_lon: string, center_lat: string): AxiosPromise {
    let paramMap = new Map<string, string>();
    paramMap.set('center_lat', center_lat);
    paramMap.set("center_lon", center_lon);
    return axiosagent.shared_bicycle_service.get('app/rectangle/company_dispatch_infos?' + HttpRequestUtil.encodeParameters(paramMap))
  }
}
