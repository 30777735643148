import RecommendEventInfo from "@/biz/recommend-event-info";

export default class RecommendEvent {
  public recommend_event_id!: string; //	主键
  public event_category!: number;	//	事件分类 1::停放点不合理 2:建议增加停放点 3:市民投诉 4:市民建议  5:乱停乱放
  public recommend_event_tags!: Array<string>;
  public deal_status!: number;	//	处理状态 1:已提交,2:已采纳,3:已驳回 4:已回复 5:未处理 6:处理中 7:已处理
  public pics!: Array<string>;
  public remark!: 	string;	//		备注
  public event_info!: 	RecommendEventInfo;	//		事件详情 见EventInfo (实际上是停车点详情)
  public region_code!: 	string;	//		行政区码
  public street_code!: 	string;	//		街道码
  public submitter!: 	string;	//		建议人
  public submitter_telephone!: 	string;	//		建议人电话
  public event_time!: 	number;	//		事件提交时间
  public recommend_location!: 	string;	//		建议地点
  public rejected_reason!: 	string;	//		驳回原因
  public reply_content!: 	string;	//		回复内容



}
