import {BicycleRequest} from "@/request/BicycleRequest";
import {AxiosError, AxiosResponse} from "axios";
import {WxAgent} from "@/wx/wx-agent";

export class WxUtil {

  public static wxConfig(debug: boolean, jsApiList: Array<string>): void {
    const url = encodeURIComponent(location.href.split('#')[0]);
    console.log('url:' + url);
    BicycleRequest.getSign(url).then((response: AxiosResponse<any>) => {
      WxAgent.config(debug, response.data.app_id,
        response.data.timestamp, response.data.noncestr,
        response.data.signature, jsApiList);
    }).catch((error: AxiosError) => {
      console.log(error);
    });
  }

  /**
   * 微信授权获取签名
   * @param {boolean} debug
   * @param {Array<string>} jsApiList
   */
  public static wxOpenIdConfig(debug: boolean, jsApiList: Array<string>): void {
    const url = encodeURIComponent(location.href.split('#')[0]);
    console.log('url:' + url);
    BicycleRequest.getWxSign(url).then((response: AxiosResponse<any>) => {
      WxAgent.config(debug, response.data.app_id,
        response.data.timestamp, response.data.noncestr,
        response.data.signature, jsApiList);
    }).catch((error: AxiosError) => {
      console.log(error);
    });
  }
}
