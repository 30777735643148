import BicycleTypeDomain from "@/domain/bicycle-type-domain";
import DispatchTypeDomain from "@/domain/dispatch-type-domain";
import ValueUtil from "@/util/value-util";
import Company from "@/biz/company";

export class BicycleUtil {

  public static getBicycleCompanyTypeName(type: number): string {
    if (BicycleTypeDomain.COMPANY_DIDI_TYPE === type) {
      return "青桔";
    } else if (BicycleTypeDomain.COMPANY_HALLO_TYPE === type) {
      return "哈啰";
    } else if (BicycleTypeDomain.COMPANY_MOBAI_TYPE === type) {
      return "美团";
    }
    return "未知";
  }

  public static getDispatchType(dispatchType: number): string {
    if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_CAR) {
      return "调度车";
    } else if (dispatchType == DispatchTypeDomain.DISPATCH_TYPE_PERSON) {
      return "调度人";
    }
    return "";
  }

  public static getBicycleType(type: number): string {
    if (type == BicycleTypeDomain.NORMAL_BICYCLE) {
      return "单车";
    } else if (type == BicycleTypeDomain.ELECTRIC_BICYCLE) {
      return "助力车";
    }
    return "";
  }


  public static getBicycleTypes(bicycleType: Array<number>): string {
    if (!ValueUtil.isArrEmpty(bicycleType)) {
      const bicycleTypeText = bicycleType.map((item) => {
          switch (item) {
            case 1:
              return '单车';
            case 2:
              return '助力车';
            default:
              return '';
          }
        }
      );
      if (bicycleTypeText) {
        return bicycleTypeText.join('、')
      }
    }
    return '';
  }

  public static getBicycleCompanys(): Company[] {
    let companys = [];
    const company1 = new Company();
    company1.companyName = '青桔';
    company1.isSelect = false;
    company1.companyType = BicycleTypeDomain.COMPANY_DIDI_TYPE;
    company1.path = require('../assets/images/icon_qj@1x.png');
    companys.push(company1);
    const company = new Company();
    company.companyName = '美团';
    company.isSelect = false;
    company.companyType = BicycleTypeDomain.COMPANY_MOBAI_TYPE;
    company.path = require('../assets/images/icon_mb@1x.png');
    companys.push(company);
    const company2 = new Company();
    company2.companyName = '哈啰';
    company2.isSelect = false;
    company2.companyType = BicycleTypeDomain.COMPANY_HALLO_TYPE;
    company2.path = require('../assets/images/icon_hb@1x.png');
    companys.push(company2);
    return companys;
  }
}
