export class RouterDomain {

  public static readonly SUPERVISE_EVENT = "SuperviseEvent";

  public static readonly PUBLIC_ADVICE = "PublicAdvice";

  public static readonly BICYCLE_INFO = "BicycleInfo";

  public static readonly MAIN = "Main";

  public static readonly EVENTPROCESS = "EventProcess";

  public static readonly SCAN_RECORD_BICYCLE = "ScanRecordBicycle";

  public static readonly statistics = "Statistics";

  public static readonly ASSESSMENT_RANKING = "AssessmentRanking";

  public static readonly COMPANY_ASSESSMENT_RANKING = "CompanyAssessmentRanking";

  public static readonly COMPANY_COORDINATE = "CompanyCoordinate";

  public static readonly REGION_RANKING = "RegionRanking";

  public static readonly REAL_TIME_MAP = "RealTimeMap";


  public static readonly SUGGESTION_MAIN = "suggestion_main";

  public static readonly SUGGESTION_RANDOM_PARKING = "suggestion_random_parking";

  public static readonly SUGGESTION_PARKING_OVER_LIMIT = "suggestion_parking_over_limit";

  public static readonly SUGGESTION_LOCATION_SELECTOR = "suggestion_location_selector";

  public static readonly SUGGESTION_PUBLIC_ADVICE = "suggestion_public_advice";

  public static readonly SUGGESTION_ADVICE_LOCATION_SELECTOR = "suggestion_advice_location_selector";

  public static readonly SUGGESTION_LIST = "suggestion_list";

  public static readonly WX_LOGIN = "wx_login";

}
