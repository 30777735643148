import HttpRequestUtil from "@/http/HttpRequestUtil";
import axiosagent from "@/http/axios_agent";

export class AdviceRequest {

  /**
   *获取建议事件标签
   * @param {number} eventCategory 事件分类 1:停放点不合理 2:建议增加停放点
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getRegulatorRecommendEventTags(eventCategory: number) {
    let paramMap = new Map<string, string>();
    paramMap.set('event_category', eventCategory.toString());
    return axiosagent.shared_bicycle_service.get('api/regulator/recommend_event_tags?'
      + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   * 微信授权获取建议事件标签
   * @param {number} eventCategory 事件分类 1:停放点不合理 2:建议增加停放点
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getWxRegulatorRecommendEventTags(eventCategory: number) {
    let paramMap = new Map<string, string>();
    paramMap.set('event_category', eventCategory.toString());
    return axiosagent.shared_bicycle_suggestion.get('wx/regulator/recommend_event_tags?'
      + HttpRequestUtil.encodeParameters(paramMap));
  }

  /**
   *创建建议事件
   * @param {number} eventCategory 事件分类 1:停放点不合理 2:建议增加停放点
   * @param {Array<string>} pics
   * @param {string} eventLocation
   * @param {string} regionCode
   * @param {number} lon
   * @param {number} lat
   * @param {string} remark
   * @param regulatorEventEagIds
   * @param parkingPointNum
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static createEecommendEvents(
    eventCategory: number,
    pics: Array<string>,
    eventLocation: string,
    regionCode: string,
    lon: number,
    lat: number,
    remark: string,
    regulatorEventEagIds: any,
    parkingPointNum: any
  ) {
    return axiosagent.shared_bicycle_service.post('api/recommend/recommend_events', {
      event_category: eventCategory,
      pics: pics,
      event_location: eventLocation,
      remark: remark,
      region_code: regionCode,
      event_lon: lon,
      event_lat: lat,
      recommend_event_tag_ids: regulatorEventEagIds,
      parking_point_num: parkingPointNum,
    });
  }

  /**
   * 获取建议事件
   * page_size  Int  F  每页条数,默认45
   * page_num  Int  F  页码，默认1
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getRecommendEvents(
    page_size?: number,
    page_num?: number, nextUrl?: string) {
    if (nextUrl) {
      return axiosagent.shared_bicycle_service.get(nextUrl);
    }
    let paramMap = new Map<string, any>();
    paramMap.set('page_size', page_size);
    paramMap.set('page_num', page_num);
    return axiosagent.shared_bicycle_service.get('api/recommend/recommend_events?' + HttpRequestUtil.encodeParameters(paramMap))
  }

  /**
   * 获取建议事件详情
   * @param {string} regulatorEventId
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getRecommendEventDetail(recommendEventId: string) {
    return axiosagent.shared_bicycle_service.get('/api/recommend/recommend_events/' + recommendEventId);
  }

  /**
   * 获取建议事件详情 todo 接口待定
   * @param {string} regulatorEventId
   * @returns {Promise<AxiosResponse<T>>}
   */
  public static getSuggestionEventDetail(recommendEventId: string) {
    return axiosagent.shared_bicycle_service.get('/api/recommend/recommend_events/' + recommendEventId);
  }
}
