import DispatchTypeDomain from "../domain/dispatch-type-domain";

export default class EventType {
  get dispatchType(): number {
    return this._dispatchType;
  }

  set dispatchType(value: number) {
    this._dispatchType = value;
  }
  get reason(): string {
    return this._reason;
  }

  set reason(value: string) {
    this._reason = value;
  }
  get isSelect(): boolean {
    return this._isSelect;
  }

  set isSelect(value: boolean) {
    this._isSelect = value;
  }
  private _isSelect = false;
  private _reason = '';
  private _dispatchType = DispatchTypeDomain.DISPATCH_TYPE_VIOLATION;

}
