export class CityCodeDomain {

  public static readonly CITY_CODE = '0459';

  public static readonly REGION_CODE_DATONG = '230606';

  public static isRightCity(cityCode: string): boolean {
    return this.CITY_CODE === cityCode;
  }


  public static isDatongRegion(regionCode: string): boolean {
    return this.REGION_CODE_DATONG === regionCode;
  }
}
