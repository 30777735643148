import {ToastUtil} from "@/util/toast-util";
import {LocalStorageAgent} from "@/storage/local-storage-agent";

export class SotrageUtil {

  public static getOpenId(): string {
    const openid = LocalStorageAgent.getWXUserInfo()?.openid;
    return openid ? openid : '';
  }
}
