export class ComponentDomain {

  public static readonly MAIN = "main";

  public static readonly SUPERVISE_EVENT = "supervise_event";

  public static readonly BICYCLE_INFO = "bicycle-info";

  public static readonly LOCATION_SELECTOR = "LocationSelector";

  public static readonly ADVICE_LOCATION_SELECTOR = "AdviceLocationSelector";

  public static readonly ADVICE_EVENT = "advice_event";

  public static readonly REAL_TIME_MAP = "RealTimeMap";



  public static readonly SUGGESTION_RANDOM_PARKING = "suggestion_random_parking";

  public static readonly SUGGESTION_MAIN = "suggestion_main";

  public static readonly SUGGESTION_LOCATION_SELECTOR = "suggestion_location_selector";

  public static readonly SUGGESTION_PARKING_OVER_LIMIT = "suggestion_parking_over_limit";

  public static readonly SUGGESTION_ADVICE_EVENT = "suggestion_advice_event";

  public static readonly SUGGESTION_ADVICE_LOCATION_SELECTOR = "suggestion_advice_location_selector";


}
